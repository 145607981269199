
body,input,button,select,textarea,pre{font-family:"\5FAE\8F6F\96C5\9ED1",Helvetica,STHeiti,Droid Sans Fallback;
}
::-webkit-input-placeholder { /* WebKit browsers */ color: #CCCCCC;
}
:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */ color: #CCCCCC;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */ color: #CCCCCC;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */ color: #CCCCCC;
}
@-webkit-keyframes dotrotate {
from { -webkit-transform: rotate(0deg); transform: rotate(0deg);
}
to { -webkit-transform: rotate(360deg); transform: rotate(360deg);
}
}
@keyframes dotrotate {
from { -webkit-transform: rotate(0deg); transform: rotate(0deg);
}
to { -webkit-transform: rotate(360deg); transform: rotate(360deg);
}
}
@-webkit-keyframes dotscale {
0%, 10% { width: 16px; height: 16px; margin-left: -8px;  margin-top: -4px;
}
50% { width: 8px; height: 8px; margin-left: -4px; margin-top: 0;
}
90%, 100% { width: 16px; height: 16px;  margin-left: -8px;  margin-top: -4px;
}
}
@keyframes dotscale {
0%, 10% { width: 16px; height: 16px; margin-left: -8px;  margin-top: -4px;
}
50% { width: 8px; height: 8px; margin-left: -4px; margin-top: 0;
}
90%, 100% { width: 16px; height: 16px;  margin-left: -8px;  margin-top: -4px;
}
}
.app-loader-main{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 100; background-color: rgba(255,255,255,0.5); text-align: center; font-size: 12px;
}
.app-loader-main .app-loader { width: 60px; height: 60px; position: absolute; z-index: 100; top: 50%; left: 50%; margin-top: -30px; margin-left: -30px;
}
.app-loader-main .app-loader .dot { position: absolute; top: 0; left: 0; width: 60px; height: 60px; -webkit-animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite;
}
.app-loader-main .app-loader .dot:nth-child(1) { -webkit-animation-delay: 0.2s; animation-delay: 0.2s;
}
.app-loader-main .app-loader .dot:nth-child(2) { -webkit-animation-delay: 0.35s; animation-delay: 0.35s;
}
.app-loader-main .app-loader .dot:nth-child(3) { -webkit-animation-delay: 0.45s; animation-delay: 0.45s;
}
.app-loader-main .app-loader .dot:nth-child(4) { -webkit-animation-delay: 0.55s; animation-delay: 0.55s;
}
.app-loader-main .app-loader .dot:after, .app-loader-main .app-loader .dot .first { content: ""; position: absolute; width: 8px; height: 8px; background: #02aff1; border-radius: 50%; left: 50%; margin-left: -4px;
}
.app-loader-main .app-loader .dot .first { background: #02aff1; margin-top: -4px; -webkit-animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite; -webkit-animation-delay: 0.2s; animation-delay: 0.2s;
}
.app-loader-main .app-message{ width: 300px; line-height: 30px; position: absolute; top: 50%; left: 50%; margin-top: 35px; margin-left: -150px;
}
.app-loader-main .app-message span{ display: inline-block; padding-right: 20px; padding-left: 20px; font-size: 12px;
}
.app-message-container{ position: absolute; z-index: 99; height: 32px; line-height: 32px; text-align: center; top: 50%; right: 0; left: 0; margin-top: -16px;
}
.app-message-container span{ display: inline-block; padding-right: 20px; padding-left: 20px; background-color: rgba(0,0,0,0.7); color: #FFF; font-size: 12px;
}
.view-app { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #F5F6FA;
}
.view-main { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.webuploader-container div, .webuploader-container input{ width: 100%!important; height: 100%!important;
}
.app-screen-header-user{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; margin: 0 50px;
}
.app-screen-header-user .user-head { width: 50px;
}
.app-screen-header-user .user-avatar { width: 30px; height: 30px; border-radius: 50%; margin: 10px auto; background-color: #EBEBEB; overflow: hidden;
}
.app-screen-header-user .user-avatar.avatar{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.app-screen-header-user .user-avatar .user-avatar-img{ width: 100%; vertical-align: inherit; margin-bottom: 10px;
}
.app-screen-header-user .user-name{ padding: 0 10px 0 0; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;
}
.bold{ font-weight: bold;
}
.mb-10{ margin-bottom: 10px;
}
.mr-10{ margin-right: 10px;
}
.order-tag{ border: 1px solid #EB3C38; border-radius: 3px; padding: 2px 4px; color: #EB3C38; font-size: 12px; margin-right: 5px;
}
.img-auto{ width: 100%; height: 100%;
}

.error-container[data-v-f5e082ec]{ padding-top: 50px; padding-right: 30px; padding-left: 30px; text-align: center;
}
.error-message[data-v-f5e082ec]{ line-height: 35px; padding-top: 20px;
}

.app-screen[data-v-74ee5994] { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #FFF;
}
.app-screen-main[data-v-74ee5994] { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.app-screen-container[data-v-74ee5994]{ padding-top: 30px; padding-right: 10px; padding-left: 10px; position: relative;
}
.app-screen-container .step-enroll[data-v-74ee5994]{ position: relative; font-size: 16px; border-bottom: 1px solid #EBEBEB; padding-bottom: 15px; padding-top: 12px;
}
.app-screen-container .step-enroll .identifier[data-v-74ee5994]{ position: absolute; top: 12px; left: 0; width: 50px; height: 25px; line-height: 25px; color: #CCC; text-align: center;
}
.app-screen-container .step-enroll .code[data-v-74ee5994]{ padding-left: 0; text-align: center;
}
.app-screen-container .step-enroll .password[data-v-74ee5994]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll input[data-v-74ee5994]{ border: 0; background-color: transparent; width: 100%; height: 25px; line-height: 25px; padding-left: 50px; outline: none;
}
.app-screen-container .step-notes[data-v-74ee5994]{ font-size: 12px; text-align: right; color: #B5B6CA; padding-top: 18px; padding-bottom: 18px; font-size: 14px;
}
.app-screen-container .step-tools[data-v-74ee5994]{ margin-top: 20px; text-align: center; height: 22px; line-height: 22px;
}
.app-screen-container .step-container[data-v-74ee5994]{ padding-top: 70px; text-align: center; font-size: 16px;
}
.app-screen-container .step-button[data-v-74ee5994]{ display: block; width: 100%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent;
}
.app-screen-container .step-button.active[data-v-74ee5994]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.app-screen-container .step-button.ignore[data-v-74ee5994]{ margin-bottom: 8px; background-color: transparent; color: #FF6068;
}

.app-scroll-main[data-v-da3439ec]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0;
}
.app-scroll-screen[data-v-da3439ec]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden;
}
.app-scroll-container[data-v-da3439ec]{ position: relative;
}

.app-screen-header[data-v-3c50a5e8]{ position: absolute; top: 0; right: 0; left: 0; height: 40px; line-height: 40px; text-align: center; background-color: rgba(255,255,255,1); z-index: 9; font-size: 18px;
}
.app-screen-header .icon-be-back[data-v-3c50a5e8]{ position: relative; overflow: hidden; padding-right: 5px; padding-left: 30px; color: #333; font-size: 14px;
}
.app-screen-header .right-button[data-v-3c50a5e8]{ position: relative; overflow: hidden;
}
.app-screen-header .icon-be-back.mirror[data-v-3c50a5e8]{ opacity: 0;
}
.app-screen-header .icon-be-back .back-button[data-v-3c50a5e8]{ position: absolute; top: 50%; left: 12px; content: ''; width: 12px; height: 12px; margin-top: -6px; border: 1px solid transparent; border-bottom-color: #333; border-left-color: #333; -webkit-transform: rotate(45deg); transform: rotate(45deg);
}
.app-screen-header.honour[data-v-3c50a5e8]{ background-color: #0e0d08;
}
.app-screen-header .t-flex[data-v-3c50a5e8]{ height: 100%;
}
.app-screen-header.honour .icon-be-back[data-v-3c50a5e8]{ color: #fff;
}
.app-screen-header.honour .content[data-v-3c50a5e8]{ color: #fff1de;
}
.app-screen-header.honour .icon-be-back .back-button[data-v-3c50a5e8]{ border-bottom-color: #fff; border-left-color: #fff;
}

.app-screen[data-v-19247814] { position: relative; width: 100%; height: 100%; -webkit-perspective: 1200px; perspective: 1200px; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; background-color: #FFF;
}
.app-screen-main[data-v-19247814] { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);
}
.app-screen-container[data-v-19247814]{ padding-top: 30px; padding-right: 10px; padding-left: 10px; position: relative;
}
.app-screen-container .step-enroll[data-v-19247814]{ position: relative; font-size: 16px; border-bottom: 1px solid #EBEBEB; padding-bottom: 15px; padding-top: 12px;
}
.app-screen-container .step-enroll .identifier[data-v-19247814]{ position: absolute; top: 12px; left: 0; width: 50px; height: 25px; line-height: 25px; color: #CCC; text-align: center;
}
.app-screen-container .step-enroll .code[data-v-19247814]{ padding-left: 0; text-align: center;
}
.app-screen-container .step-enroll .password[data-v-19247814]{ padding-right: 10px; padding-left: 10px;
}
.app-screen-container .step-enroll input[data-v-19247814]{ border: 0; background-color: transparent; width: 100%; height: 25px; line-height: 25px; padding-left: 50px; outline: none;
}
.app-screen-container .step-notes[data-v-19247814]{ font-size: 12px; text-align: right; color: #B5B6CA; padding-top: 18px; padding-bottom: 18px; font-size: 14px;
}
.app-screen-container .step-tools[data-v-19247814]{ margin-top: 20px; text-align: center; height: 22px; line-height: 22px;
}
.app-screen-container .step-container[data-v-19247814]{ padding-top: 70px; text-align: center; font-size: 16px;
}
.app-screen-container .step-button[data-v-19247814]{ display: block; width: 100%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent;
}
.app-screen-container .step-button.active[data-v-19247814]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.app-screen-container .step-button.ignore[data-v-19247814]{ margin-bottom: 8px; background-color: transparent; color: #FF6068;
}

.staff-center-header[data-v-0890390d]{ height: 140px; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16); position: relative; overflow: hidden;
}
/*.staff-center-header::after{ content: ''; position: absolute; height: 20px; right: 0; bottom: -10px; left: 0; background-color: #FFF; border-radius: 10px; }*/
.staff-center-header-container[data-v-0890390d]{ position: absolute; right: 10px; bottom: 30px; left: 10px; height: 60px; padding-left: 70px;
}
.staff-center-header-container .member-head[data-v-0890390d]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.staff-center-header-container .member-head.avatar[data-v-0890390d]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.staff-center-header-container .member-head img[data-v-0890390d]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.staff-center-header-container .member-name[data-v-0890390d]{ font-size: 18px; color: #FFF; height: 60px; line-height: 60px;
}
.staff-center-header-container .member-group[data-v-0890390d]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px; margin-bottom: 4px;
}
.personal-center-line[data-v-0890390d]{ display: block; overflow: hidden; height: 12px;
}
.personal-center-headline[data-v-0890390d]{ padding-right: 10px; padding-left: 10px; height: 30px; line-height: 30px; color: rgba(95, 100, 115, 0.57); font-size: 12px; background-color: #FFF; position: relative;
}
.personal-center-headline[data-v-0890390d]::after{ content: ''; position: absolute; right: 8px; bottom: 0; left: 8px; height: 1px; background-color: #F5F6FA;
}
.personal-center-menu[data-v-0890390d]{ position: relative; font-size: 16px; padding-right: 10px; padding-left: 10px; background-color: #FFF;
}
.personal-center-menu .col-line[data-v-0890390d]{ height: 50px; line-height: 50px; overflow: hidden; position: relative; padding-left: 38px; padding-right: 22px; background-size: 28px; background-position: 0 50%; background-repeat: no-repeat;
}
.personal-center-menu .col-line[data-v-0890390d]::after{ content: ''; position: absolute; right: 0px; bottom: 0; left: 0px; height: 1px; overflow: hidden; background-color: #E5E5E5;
}
.personal-center-menu .col-line[data-v-0890390d]:last-child::after{ display: none;
}
.personal-center-menu .col-line[data-v-0890390d]::before{ content: ''; position: absolute; right: 5px; top: 50%; width: 8px; height: 8px; margin-top: -4px; border: 1px solid transparent; border-top-color: #999; border-right-color: #999; -webkit-transform: rotate(45deg); transform: rotate(45deg);
}
.personal-center-menu .col-line.icon-line-card[data-v-0890390d]{ background-image: url(/static/images/icon-line-card.png);
}
.personal-center-menu .col-line.icon-line-collect[data-v-0890390d]{ background-image: url(/static/images/icon-line-collect.png);
}
.personal-center-menu .col-line.icon-line-history[data-v-0890390d]{ background-image: url(/static/images/icon-line-history.png);
}
.personal-center-menu .col-line.icon-line-member[data-v-0890390d]{ background-image: url(/static/images/icon-line-member.png);
}
.personal-center-menu .col-line.icon-line-fans[data-v-0890390d]{ background-image: url(/static/images/icon-line-fans.png);
}
.personal-center-menu .col-line.icon-line-order[data-v-0890390d]{ background-image: url(/static/images/icon-line-order.png);
}
.personal-center-menu .col-line.icon-line-statistics[data-v-0890390d]{ background-image: url(/static/images/icon-line-statistics.png);
}
.personal-center-menu .col-line.icon-line-customer[data-v-0890390d]{ background-image: url(/static/images/icon-line-customer.png);
}
.personal-center-menu .col-line.icon-line-equipment[data-v-0890390d]{ background-image: url(/static/images/icon-line-equipment.png);
}
.personal-center-menu .col-line.icon-line-ranking-list[data-v-0890390d]{ background-image: url(/static/images/icon-line-ranking-list.png);
}
.personal-center-menu .col-line.icon-line-honour[data-v-0890390d]{ background-image: url(/static/images/icon-line-honour.png);
}
.personal-center-menu .col-line.icon-line-order-group[data-v-0890390d]{ background-image: url(/static/images/icon-line-order-group.png);
}
.personal-center-menu .col-line.icon-line-custom[data-v-0890390d]{ background-image: url(/static/images/custom-list.png);
}
.personal-center-menu .col-line.icon-line-purchase[data-v-0890390d]{ background-image: url(/static/images/purchase.png);
}
.personal-center-menu .col-line.icon-line-bargain[data-v-0890390d]{ background-image: url(/static/images/bargain-list.png);
}
.personal-center-menu .col-line.icon-line-card[data-v-0890390d]{ background-image: url(/static/images/card-list.png);
}
.personal-center-menu .col-line.icon-line-discount[data-v-0890390d]{ background-image: url(/static/images/discount-list.png);
}
.personal-center-menu .col-line.icon-line-secondkill[data-v-0890390d]{ background-image: url(/static/images/secondkill-list.png);
}
.personal-center-menu .col-line.icon-line-fight[data-v-0890390d]{ background-image: url(/static/images/fight-list.png);
}
.personal-center-menu .col-line.icon-line-special[data-v-0890390d]{ background-image: url(/static/images/special-list.png);
}
.personal-center-menu .col-line.icon-line-assistance[data-v-0890390d]{ background-image: url(/static/images/assistance.png);
}
.personal-center-menu .col-line.icon-line-people[data-v-0890390d]{ background-image: url(/static/images/people-list.png);
}
.personal-center-menu .col-line.icon-line-staff-logout[data-v-0890390d]{ background-image: url(/static/images/icon-line-staff-logout.png); color: #EB3C38;
}
.personal-center-menu .col-line.icon-line-staff-logout[data-v-0890390d]::before{ border-top-color: #EB3C38; border-right-color: #EB3C38;
}
.staffMiniQr-box[data-v-0890390d]{ position: fixed; width: 100%; background-color: #2d3132; height: 100%; top: 0; left: 0; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 20px;
}
.mini-qr-box[data-v-0890390d]{ text-align: center; width: 90%; background-color: #fff; border-radius: 2px; padding: 20px; margin: 0 auto 50px;
}
.mini-qr-bd[data-v-0890390d]{ margin: 10px 0; min-height: 100px;
}
.mini-qr[data-v-0890390d]{ width: 100%; margin: 0 auto; display: block;
}
.mini-qr-remark[data-v-0890390d]{ margin-top: 20px;
}
.mini-qr-head[data-v-0890390d]{ text-align: left;
}
.staff-account[data-v-0890390d]{ font-weight: bold;
}
.step-button[data-v-0890390d]{ display: block; width: 80%; height: 50px; line-height: 50px; text-align: center; background-color: #CCCCCC; color: #FFF; border-radius: 25px; border: 1px solid transparent; margin: 0 auto;
}
.step-button.active[data-v-0890390d]{ border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}

.app-screen-footer[data-v-188d4f69]{ position: absolute; right: 0; bottom: 0; left: 0; height: 50px; text-align: center; z-index: 9;
}
.app-screen-footer .footer-container[data-v-188d4f69]{ position: relative; padding-bottom: 23px; height: 50px;
}
.app-screen-footer .footer-container-icon[data-v-188d4f69]{ position: absolute; top: 2px; right: 0; bottom: 23px; left: 0; line-height: 1em;
}
.app-screen-footer .footer-container-icon img[data-v-188d4f69]{ max-width: 30px; max-height: 30px;
}
.app-screen-footer .footer-container-headline[data-v-188d4f69]{ position: absolute; right: 0; bottom: 0; left: 0; height: 23px; line-height: 23px;
}

.custommall-order-section[data-v-1b70f458]{ padding-top: 10px;
}
.custommall-order-body[data-v-1b70f458]{ position: relative; background-color: #FFF;
}
.custommall-order-headline[data-v-1b70f458]{ height: 40px; line-height: 40px; overflow: hidden; padding-right: 10px; padding-left: 10px; color: #555;
}
.custommall-order-headline .order-state[data-v-1b70f458]{ color: #EB3C38;
}
.custommall-order-context[data-v-1b70f458]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-1b70f458]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context[data-v-1b70f458]:first-child{ margin-top: 0px !important;
}
.custommall-order-context .order-context-image[data-v-1b70f458]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-1b70f458]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-1b70f458]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-1b70f458]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-1b70f458]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-param[data-v-1b70f458]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-1b70f458]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-1b70f458]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-1b70f458]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-1b70f458]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-1b70f458]{ font-size: 12px;
}
.custommall-order-context .order-price[data-v-1b70f458]{ color: #EB3C38; padding-left: 5px;
}
.custommall-order-context .order-state[data-v-1b70f458]{ text-align: right;
}
.custommall-order-context .order-staff[data-v-1b70f458]{ display: block; width: 100%; font-size: 12px; color: #999; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; font-size: 12px; color: #999;
}
.custommall-order-context .order-button[data-v-1b70f458]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px; clear: both;
}
.custommall-order-context .order-button button[data-v-1b70f458]{ border-radius: 15px; border: 1px solid #C3C3C3; background-color: transparent; color: #C3C3C3; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-1b70f458]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-extend[data-v-1b70f458]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-1b70f458]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-1b70f458]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-1b70f458]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-1b70f458]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-1b70f458]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-select[data-v-1b70f458]{ border: 1px solid #EBEBEB; background: transparent; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-input[data-v-1b70f458]{ border: 1px solid #EBEBEB; height: 40px; line-height: 40px; margin-bottom: 10px; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 0px 8px;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-1b70f458]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-1b70f458]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-header[data-v-1b70f458]{ height: 40px; line-height: 40px; text-align: center; background-color: #FFF; overflow-x: scroll; overflow-y: hidden; -webkit-overflow-scrolling: touch;
}
.custommall-order-header[data-v-1b70f458]::-webkit-scrollbar{ display: none;
}
.custommall-order-header .order-state-item[data-v-1b70f458]{ position: relative; display: inline-block; width: auto; padding: 0 15px;
}
.custommall-order-header .order-state-item[data-v-1b70f458]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 2px; overflow: hidden; background: transparent;
}
.custommall-order-header .order-state-item.active[data-v-1b70f458]{ color: #EB3C38;
}
.custommall-order-header .order-state-item.active[data-v-1b70f458]::after{ background: #EB3C38;
}
.order-modal-from-staff[data-v-1b70f458] { color: #999; padding: 10px 0; margin: 0 10px; border: 1px solid #eaeaea; border-left: none; border-right: none;
}
.custommall-ranking-header[data-v-1b70f458]{ position: relative; padding-top: 50px;
}
.custommall-ranking-search-content[data-v-1b70f458]{ height: 40px; line-height: 40px; position: relative; font-size: 14px; background-color: #FFF;
}
.custommall-ranking-search-content[data-v-1b70f458]::after{ content: ''; position: absolute; right: 0; bottom: 0; left: 0; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-search-content .input-container[data-v-1b70f458]{ padding-right: 10px; padding-left: 10px; overflow: hidden; position: relative;
}
.custommall-ranking-search-content .input-container input[data-v-1b70f458]{ display: block; width: 100%; height: 40px; line-height: 1em; border: 0; background-color: transparent; outline: none;
}
.custommall-ranking-search-content .input-button[data-v-1b70f458]{ width: 60px; height: 40px; background: url(/static/images/icon-search.png) 50% 50% no-repeat; background-size: 20px;
}
.custommall-ranking-search-content .icon-sorting-button[data-v-1b70f458]{ width: 40px; height: 40px; background: url(/static/images/icon-sorting.png) 50% 50% no-repeat; background-size: 20px;
}
.icon-shop-button[data-v-1b70f458]{ width: 40px; height: 40px; background: url(/static/images/icon-shop.png) 50% 50% no-repeat; background-size: 23px;
}
.icon-date-button[data-v-1b70f458]{ width: 40px; height: 40px; background: url(/static/images/icon-date.png) 50% 50% no-repeat; background-size: 23px;
}
.custommall-ranking-sorting[data-v-1b70f458]{ position: absolute; right: 0px; width: 120px; background-color: #FFF; z-index: 99;
}
.custommall-ranking-sorting-item[data-v-1b70f458]{ height: 28px; line-height: 28px; padding-left: 15px; padding-right: 15px; overflow: hidden; border-left: solid 2px transparent; color: #999; position: relative;
}
.custommall-ranking-sorting-item.active[data-v-1b70f458]{ color: #EB3C38; border-left-color: #EB3C38;
}
.custommall-ranking-sorting-item[data-v-1b70f458]::after{ content: ''; position: absolute; right: 10px; bottom: 0; left: 10px; height: 1px; overflow: hidden; background: #EBEBEB;
}
.custommall-ranking-sorting-item[data-v-1b70f458]::before{ content: ''; display: inline-block; width: 0; height: 0; border: 4px solid #999; float: right; margin-top: 12px; border-color: #999 transparent transparent transparent;
}
.custommall-ranking-sorting-item.order-desc[data-v-1b70f458]::before{ border-color: transparent transparent #999 transparent; margin-top: 8px;
}
.remove-sorting[data-v-1b70f458]:before{ display: none;
}
.text-red[data-v-1b70f458]{ color: #EB3C38;
}
.custommall-ranking-header-people[data-v-1b70f458]{ width: 100%; height: 40px; line-height: 40px; background-color: #fff; padding: 0 10px;
}
.equipment-modal-from-checkbox[data-v-1b70f458]{ display: block; text-align: left; font-weight: normal; font-size: 12px;
}
.equipment-modal-from-checkbox input[data-v-1b70f458]{ top: 2px; position: relative; margin-right: 8px;
}
.order-state-container[data-v-1b70f458]{ white-space: nowrap;
}
.text-danger[data-v-1b70f458]{ color: #EB3C38;
}
.top-tips[data-v-1b70f458] { padding: 5px 10px; background-color: #fef8e8; color: #eab033; font-size: 12px;
}
.top-tips-icon[data-v-1b70f458] { margin-right: 5px; color: #fea003;
}
.selectd-date[data-v-1b70f458] { padding: 10px 5px 0 5px; color: #999;
}
.shop-name[data-v-1b70f458]{ width: 100px;
}
.barcode[data-v-1b70f458]{ width: 100%; height: 100px;
}
.mobileSelect { position: relative; z-index: 0; opacity: 0; visibility: hidden; -webkit-transition: opacity 0.4s, z-index 0.4s; transition: opacity 0.4s, z-index 0.4s; }
.mobileSelect * { margin: 0; padding: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
.mobileSelect .grayLayer { position: fixed; top: 0; left: 0; bottom: 0; right: 0; background: #eee; background: rgba(0, 0, 0, 0.5); z-index: 888; display: block; }
.mobileSelect .content { width: 100%; display: block; position: fixed; z-index: 889; color: black; -webkit-transition: all 0.4s; transition: all 0.4s; bottom: -350px; left: 0; background: white; }
.mobileSelect .content .fixWidth { width: 100%; position: relative; }
.mobileSelect .content .fixWidth:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.mobileSelect .content .btnBar { border-bottom: 1px solid #DCDCDC; font-size: 15px; height: 45px; position: relative; text-align: center; line-height: 45px; }
.mobileSelect .content .btnBar .cancel,
.mobileSelect .content .btnBar .ensure { height: 45px; width: 55px; cursor: pointer; position: absolute; top: 0; }
.mobileSelect .content .btnBar .cancel { left: 0; color: #666; font-size: 16px; }
.mobileSelect .content .btnBar .ensure { right: 0; color: #1e83d3; font-size: 16px; }
.mobileSelect .content .btnBar .title { font-size: 15px; padding: 0 15%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.mobileSelect .content .panel:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
.mobileSelect .content .panel .wheels { width: 100%; height: 200px; overflow: hidden; }
.mobileSelect .content .panel .wheel { position: relative; z-index: 0; float: left; width: 50%; height: 200px; overflow: hidden; -webkit-transition: width 0.3s ease; transition: width 0.3s ease; }
.mobileSelect .content .panel .wheel .selectContainer { display: block; text-align: center; -webkit-transition: -webkit-transform 0.18s ease-out; transition: -webkit-transform 0.18s ease-out; transition: transform 0.18s ease-out; transition: transform 0.18s ease-out, -webkit-transform 0.18s ease-out; }
.mobileSelect .content .panel .wheel .selectContainer li { font-size: 16px; display: block; height: 40px; line-height: 40px; cursor: pointer; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.mobileSelect .content .panel .selectLine { height: 40px; width: 100%; position: absolute; top: 80px; pointer-events: none; -webkit-box-sizing: border-box; box-sizing: border-box; border-top: 1px solid #DCDCDC; border-bottom: 1px solid #DCDCDC; }
.mobileSelect .content .panel .shadowMask { position: absolute; top: 0; width: 100%; height: 200px; background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(rgba(255, 255, 255, 0)), to(#ffffff)); background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0), #ffffff); opacity: 0.9; pointer-events: none; }
.mobileSelect-show { opacity: 1; z-index: 10000; visibility: visible; }
.mobileSelect-show .content { bottom: 0; }

.ProCustomSDiv[data-v-41104215]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9;
}
.ProCustomSDiv .ProCustomSDivYY[data-v-41104215]{ width: 100%; height: 100%; background-color: #333; opacity: .7
}
.ProCustomSDiv-container[data-v-41104215]{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav[data-v-41104215]{width: 70%; background-color: #fff; position: absolute; left: 50%; top: 180px; -webkit-transform: translateX(-50%); transform: translateX(-50%); border-radius: 10px; -webkit-border-radius: 10px; -moz-border-radius: 10px; padding: 14px; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-41104215]{
    width: 100%; height: 120px; padding: 15px; padding-left: 100px; padding-right: 0; border-bottom: 1px solid #ccc; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-41104215]:nth-child(1){position: absolute; width: 90px; height: 90px; top: 15px; left: 0; border: 1px solid #bbb; -webkit-box-sizing: border-box; box-sizing: border-box; border-radius: 8px; -webkit-border-radius: 8px; -moz-border-radius: 8px; overflow: hidden;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div[data-v-41104215]:nth-child(2){width: 100%; height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div:nth-child(2) span[data-v-41104215]{display: block; font-size: 13px; color: #333;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs div img[data-v-41104215]{position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); height: 100%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs[data-v-41104215]:nth-child(3) {padding-left: 72%;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-41104215]:nth-child(1){width: 72%; text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) div[data-v-41104215]:last-of-type{text-align: center;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer[data-v-41104215]{ width: 30px; height: 30px; position: absolute; top: 20px; left: 8px;
}
.ProCustomSDiv .ProCustomSDivNav .ProCustomSDivNavs:nth-child(3) .orderDataImgPlayer img[data-v-41104215]{ display: block; width: 100%; height: 100%;border: none;
}
.ProCustomSDiv .orderBigImage[data-v-41104215]{position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 999;
}
.ProCustomSDiv .orderBigImage img[data-v-41104215]{display: block; width: 60%; height: auto; margin:200px auto;
}
.custommall-order-header[data-v-41104215]{ position: relative; overflow: hidden;
}
.custommall-order-header[data-v-41104215]::after{ content: ''; position: absolute; top: 0; right: 0; left: 0; height: 70%; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.custommall-order-header-container[data-v-41104215]{ margin: 30px 15px; padding-left: 70px; position: relative; z-index: 9;
}
.custommall-order-header-container .member-head[data-v-41104215]{ width: 60px; height: 60px; overflow: hidden; position: absolute; top: 0; left: 0; background-color: #FFF; border-radius: 50%;
}
.custommall-order-header-container .member-head.avatar[data-v-41104215]{ background-image: url(/static/images/icon-avatar.png); background-position: center; background-repeat: no-repeat; background-size: contain;
}
.custommall-order-header-container .member-head img[data-v-41104215]{ max-width: 100%; max-height: 100%; vertical-align: inherit;
}
.custommall-order-header-container .member-name[data-v-41104215]{ font-size: 18px; color: #FFF; height: 30px; line-height: 30px;
}
.custommall-order-header-container .member-group[data-v-41104215]{ display: inline-block; position: relative; padding-top: 4px; padding-bottom: 4px; padding-right: 6px; padding-left: 6px; background-color: #FFF; border-radius: 20px; color: #989FCA; font-size: 12px; margin-bottom: 5px;
}
.custommall-order-header-address[data-v-41104215]{ margin: 0 15px 15px; padding-bottom: 15px; overflow: hidden; display: block; border-radius: 6px; background-color: #FFF; position: relative; z-index: 9;
}
.custommall-order-header-address .order-address-icon[data-v-41104215]{ width: 35px; height: 60px; display: block; background: url(/static/images/icon-position.png) center no-repeat; background-size: 25px;
}
.custommall-order-header-address .order-address-headline[data-v-41104215]{ height: 50px; line-height: 50px; text-align: left; padding-left: 5px; padding-right: 20px; font-size: 16px;
}
.custommall-order-header-address .order-address-context[data-v-41104215]{ padding-left: 5px; padding-right: 15px; font-size:12px; opacity: 0.7;
}
.order-detailed[data-v-41104215]{ padding-top: 15px; padding-bottom: 15px; padding-right: 10px; padding-left: 10px;
}
.order-detailed-line[data-v-41104215]{ height: 23px; line-height: 23px; font-size: 12px;
}
.custommall-order-section[data-v-41104215]{ padding-top: 0;
}
.custommall-order-body[data-v-41104215]{ position: relative; background-color: #FFF;
}
.custommall-order-context[data-v-41104215]{ position: relative; display: block; overflow: hidden;
}
.custommall-order-context .order-context[data-v-41104215]{ height: 100px; padding-left: 110px; padding-right: 10px; position: relative; overflow: hidden; margin-top: 10px;
}
.custommall-order-context .order-context-image[data-v-41104215]{ position: absolute; top: 5px; bottom: 5px; left: 10px; width: 90px; background-color: #FAFAFA; border-radius: 6px; overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
}
.custommall-order-context .order-context-image img[data-v-41104215]{ max-width: 100%; max-height: 100%;
}
.custommall-order-context .order-context-body[data-v-41104215]{ line-height: 25px;
}
.custommall-order-context .order-context-body .order-headline[data-v-41104215]{ height: 50px; line-height: 25px;
}
.custommall-order-context .order-context-body .order-custom[data-v-41104215]{ opacity: 0.7;
}
.custommall-order-context .order-context-body .order-custom .order-custom-span[data-v-41104215]{ color: blue; border-bottom: 1px solid #0000FF;padding:0 4px;margin:0 4px;-webkit-box-sizing: border-box;box-sizing: border-box;
}
.custommall-order-context .order-context-body .order-param[data-v-41104215]{ padding-left: 10px;
}
.custommall-order-context .order-context-body .order-price del[data-v-41104215]{ display: block; color: #CCC;
}
.custommall-order-context .order-context-body .order-quantity[data-v-41104215]{ height: 50px; line-height: 50px; text-align: right;
}
.custommall-order-context .order-footer[data-v-41104215]{ height: 50px; line-height: 50px; text-align: right; padding-right: 15px; padding-left: 15px;
}
.custommall-order-context .order-footer-price[data-v-41104215]{ font-weight: bold; font-size: 16px;
}
.custommall-order-context .order-footer-price-sub[data-v-41104215]{ font-size: 12px;
}
.custommall-order-context .order-button[data-v-41104215]{ text-align: right; padding-right: 15px; padding-left: 15px; padding-bottom: 15px;
}
.custommall-order-context .order-button button[data-v-41104215]{ border-radius: 15px; border: 1px solid #555; background-color: transparent; color: #555; font-size: 12px; padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;
}
.custommall-order-context .order-button button.confirm[data-v-41104215]{ border: 1px solid #EB3C38; background-color: transparent; color: #EB3C38;
}
.custommall-order-detailed[data-v-41104215]{ margin-top: 15px; background-color: #FFF; padding-right: 10px; padding-left: 10px; font-size: 12px; padding-top: 10px; padding-bottom: 10px;
}
.custommall-order-detailed .order-container-line[data-v-41104215]{ height: 22px; line-height: 22px;
}
.custommall-order-extend[data-v-41104215]{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255,1);
}
.custommall-order-extend.custommall-order-modal-from[data-v-41104215]{ background-color: rgba(0,0,0,0.5);
}
.custommall-order-modal-from .order-modal-from-close[data-v-41104215]{ width: 280px; margin: 0 auto; height: 40px;
}
.custommall-order-modal-from .order-modal-from-close .icon-close[data-v-41104215]{ display: block; width: 40px; height: 40px; line-height: 40px; float: right; background: url(/static/images/icon-close.png) center no-repeat; background-size: 20px;
}
.custommall-order-modal-from .order-modal-from[data-v-41104215]{ width: 280px; margin: 0 auto; background-color: #FFF; border-radius: 8px; padding-right: 20px; padding-left: 20px; padding-bottom: 20px;
}
.custommall-order-modal-from .order-modal-from-headline[data-v-41104215]{ height: 70px; line-height: 70px; font-size: 20px; font-weight: bold;
}
.custommall-order-modal-from .order-modal-from-textarea[data-v-41104215]{ border: 1px solid #EBEBEB; outline: none; border-radius: 6px; resize: none; font-size: 12px; width: 100%; padding: 8px;
}
.custommall-order-modal-from .order-modal-from-button[data-v-41104215]{ height: 40px; line-height: 40px; margin-top: 20px; color: #FFF; border-radius: 20px; border-color: #FF6068; background: -webkit-gradient(linear, left top, left bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(#FE4B4B, #E81B16);
}
.custommall-order-footer[data-v-41104215]{ display: block; text-align: left; padding-left: 10px; color: #333;
}
.custommall-order-footer .footer-content[data-v-41104215]{ height: 50px; line-height: 50px; font-size: 16px;
}
.custommall-order-footer .order-price[data-v-41104215]{ font-weight: bold; font-size: 16px; color: #E81B16;
}
.custommall-order-footer .order-price-sub[data-v-41104215]{ font-size: 12px; margin-right: 4px;
}
.flex-center[data-v-41104215]{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
}
.text-xs[data-v-41104215]{ font-size: 12px;
}
.express-detail[data-v-41104215]{ height: 400px; overflow-y: auto; -webkit-overflow-scrolling: touch;
}

.app-screen-footer[data-v-142dc9f6]{ position: absolute; right: 0; bottom: 0; left: 0; height: 50px; text-align: center; z-index: 9;
}
.app-screen-footer .footer-context[data-v-142dc9f6]{ padding-right: 10px;
}
.app-screen-footer .footer-container[data-v-142dc9f6]{ height: 50px; overflow: hidden;
}
.app-screen-footer .footer-button[data-v-142dc9f6]{ position: relative; height: 35px; margin-top: 7.5px; margin-right: 10px; font-size: 14px; border: 1px solid transparent; padding-right: 10px; padding-left: 10px; border-radius: 25px;
}
.app-screen-footer .footer-button-headline[data-v-142dc9f6]{ display: inline-block; height: 35px; line-height: 33px;
}
.app-screen-footer .footer-button.active[data-v-142dc9f6]{ border-color: #FF6068; color: #FFF; background: -webkit-gradient(linear, left top, right bottom, from(#FE4B4B), to(#E81B16)); background: linear-gradient(to bottom right, #FE4B4B, #E81B16);
}
.app-screen-footer .footer-button.default[data-v-142dc9f6]{ border-color: transparent; color: #FFF; background: -webkit-gradient(linear, left top, right bottom, from(#CCCCCC), to(#DBDBDB)); background: linear-gradient(to bottom right, #CCCCCC, #DBDBDB);
}
